import React, { Fragment, useState } from "react"
import { graphql } from "gatsby"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import Meta from "../components/meta"
import Layout from "../components/layout"
import DummyHero from "../components/dummy-hero"
import logo from "../images/logo.png"
import { BiChevronRightCircle } from "react-icons/bi"
import { Dialog, Transition } from "@headlessui/react"
import { Formik, Form, Field } from "formik" // Form validation
import * as Yup from "yup" // Form validation
import { TextField } from "../components/text-field"
import { FileUploader } from "react-drag-drop-files"
import { ToastContainer, toast } from "react-toastify" // toastify
import "react-toastify/dist/ReactToastify.css" // toastify
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import Checkbox from "../components/checkbox"
import { HiX } from "react-icons/hi"
import "./career.css"

function Career({ data }) {
  console.log({ data })
  const { executeRecaptcha } = useGoogleReCaptcha()

  const title = data.strapiCareer.title
  const type = data.strapiCareer.type

  const portfolio = data.strapiCareer.portfolio
  const isPortfolioMandatory =
    data?.strapiCareer?.is_portfolio_mandatory ?? true
  const videoLink = data?.strapiCareer?.video_link ?? false
  // const formLink = `${form}?position=${title}&time=${type}&portfolio=${portfolio}`;
  const open_to = data.strapiCareer.open_to?.data?.open_to
  // const requirements = data.strapiCareer.requirements.data?.requirements;
  const working_day = data.strapiCareer?.working_day?.data?.working_day ?? ""
  const working_hour = data.strapiCareer?.working_hour?.data?.working_hour ?? ""
  const number_of_hiring = data.strapiCareer?.number_of_hiring
  const job_description =
    data.strapiCareer.job_description?.data?.job_description
  const job_requirement =
    data.strapiCareer.job_requirement?.data?.job_requirement
  const offer = data.strapiCareer.offer?.data?.offer
  const baseUrl =
    process.env.STRAPI_API_URL ?? "https://strapi-admin.digitallaboratory.net"
  // const baseUrl = "http://localhost:1337";

  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false) // Modal popup
  const [cvFile, setCVFile] = useState(null) // drag and drop
  const [sampleArticleFile, setSampleArticleFile] = useState(null) // drag and drop
  const [isSampleArticleNeeded, setSampleArticleNeeded] = useState(
    data.strapiCareer.sample_article ?? false
  ) // drag and drop
  // const [filename, setFilename] = useState(null); // drag and drop file name
  const fileTypes = ["pdf", "docx", "doc"] // File types

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const urlRegex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
  // conditional validation portfolio
  const portfolioCondition = {}
  if (portfolio && isPortfolioMandatory) {
    portfolioCondition.portfolio = Yup.string()
      .matches(
        urlRegex,
        "Enter correct url!"
      )
      .required("Portfolio is required")
  }

  if (portfolio && !isPortfolioMandatory) {
    portfolioCondition.portfolio = Yup.string().matches(
      urlRegex,
      "Enter correct url!"
    )
  }

  // validations
  const validate = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    "video_link": Yup.string().matches(urlRegex, "Invalid Video Link"),  
    phone: Yup.number()
      .typeError("Please enter a valid phone number")
      .positive("A phone number can't start with a minus")
      .integer("Please enter a valid phone number")
      .required("A phone number is required"),
    shift: Yup.string().required("Telephone is required"),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      "You need to accept the privacy policy"
    ),
    ...portfolioCondition,
  })

  const handleCVChange = file => {
    setCVFile(file)
  }

  const handleSampleArticleChange = file => {
    setSampleArticleFile(file)
  }

  // Career submit
  const formSubmit = async data => {
    if (loading) return
    setLoading(true)
    // Only work if Google rechapha is running
    if (!executeRecaptcha) {
      setLoading(false)
      return
    }

    //Check if cv file is selected
    if (isSampleArticleNeeded && !sampleArticleFile) {
      toast.error("Please Upload Sample Article ", {
        pauseOnHover: true,
      })
      setLoading(false)
      return
    }

    //Check if cv file is selected
    if (!cvFile) {
      toast.error("Please Upload CV", {
        pauseOnHover: true,
      })
      setLoading(false)
      return
    }

    // Automatically set shift to Full time
    if (type !== "Part Time") {
      data.shift = type
    }

    //set dummy score for backend
    data.score = -1

    //Init form data to send
    const formData = new FormData();

    const token = await executeRecaptcha("jobApplication")

    //Get file extension
    const cvExtension = cvFile.name.split(".").pop()
    const cvFilename = `${data.name}_CV.${cvExtension}`
    // Set Cv file in form data
    formData.append("files.cv", cvFile, cvFilename)

    if (isSampleArticleNeeded) {
      //Get file extension
      const sampleArticleExtension = sampleArticleFile.name.split(".").pop()
      const sampleArticleFilename = `${data.name}_Sample_Article.${sampleArticleExtension}`
      formData.append("files.sample", sampleArticleFile, sampleArticleFilename)
    }

    //Convert data object to form data.
    formData.append("data", JSON.stringify(data))
    formData.append("token", token)

    const url = baseUrl + "/api/job-application/submit"
    let ok = false
    try {
      const submitResult = await fetch(url, {
        method: "POST",
        // headers: {
        //     'Content-Type': 'application/json'
        // },
        body: formData,
      })

      ok = submitResult.ok
    } catch (error) {
      console.error(error)
    }
    if (ok) {
      toast.success("Application Form is sent successfully", {
        pauseOnHover: true,
      })
      //Close dialog
      setCVFile(null)
      setIsOpen(false)
    } else {
      toast.error("Something went wrong", {
        pauseOnHover: true,
      })
    }

    setLoading(false)
  }

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {/* Form Modal */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="flex items-center justify-between dl-h4"
                  >
                    <p>Send Us Your Application Form</p>
                    <button onClick={closeModal}>
                      <HiX className="w-6 h-6 text-dll-title" />
                    </button>
                  </Dialog.Title>

                  <div className="careerForm">
                    <Formik
                      initialValues={{
                        position: title,
                        name: "",
                        email: "",
                        phone: "",
                        shift: "8 AM ~ 12 AM",
                        video_link: "",
                        termsAndConditions: false,
                      }}
                      validationSchema={validate}
                      onSubmit={formSubmit}
                    >
                      {formik => (
                        <div>
                          <Form>
                            <div className="careerForm__form">
                              <TextField
                                singleLine={true}
                                label="Position"
                                name="position"
                                type="text"
                                placeholder=""
                                disabled
                              />

                              <TextField
                                singleLine={true}
                                label="Full Name"
                                name="name"
                                type="text"
                                placeholder="Name *"
                              />

                              <TextField
                                singleLine={true}
                                label="Email Address"
                                name="email"
                                type="email"
                                placeholder="Email *"
                              />

                              <TextField
                                singleLine={true}
                                label="Telephone"
                                name="phone"
                                type="text"
                                placeholder="Telephone *"
                              />

                              {portfolio && (
                                <TextField
                                  singleLine={true}
                                  label="Portfolio"
                                  name="portfolio"
                                  type="text"
                                  placeholder={`Portfolio ${
                                    isPortfolioMandatory ? "*" : ""
                                  }`}
                                />
                              )}

                              {videoLink && (
                                <TextField
                                  singleLine={true}
                                  label="Sample Video Link"
                                  name="video_link"
                                  type="text"
                                  placeholder={`Sample Video link`}
                                />
                              )}

                              {type === "Part Time" && (
                                <>
                                  <p className="dl-p2">Shift Time</p>
                                  <div className="flex gap-3 mb-3">
                                    <Field
                                      type="radio"
                                      id="morning"
                                      name="shift"
                                      value="8 AM ~ 12 AM"
                                    />
                                    <label htmlFor="morning">
                                      8 AM ~ 12 AM
                                    </label>
                                    <Field
                                      type="radio"
                                      id="evening"
                                      name="shift"
                                      value="1 PM ~ 5 PM"
                                    />
                                    <label htmlFor="evening">1 PM ~ 5 PM</label>
                                  </div>
                                </>
                              )}

                              {isSampleArticleNeeded && (
                                <div className="py-3">
                                  <label
                                    className="text-[#666666]"
                                    htmlFor="sampleFile"
                                  >
                                    Sample Article with PDF or Word format. (Max
                                    10MB) *
                                  </label>
                                  <FileUploader
                                    handleChange={handleSampleArticleChange}
                                    name="sampleFile"
                                    types={fileTypes}
                                  />
                                  <p className="mt-2 text-sm text-gray-500">
                                    {sampleArticleFile
                                      ? `File name: ${sampleArticleFile.name}`
                                      : "no files uploaded yet"}
                                  </p>
                                </div>
                              )}

                              <div className="py-3">
                                <label
                                  className="text-[#666666]"
                                  htmlFor="cvFile"
                                >
                                  CV with PDF or Word format. (Max 10MB) *
                                </label>
                                <FileUploader
                                  handleChange={handleCVChange}
                                  name="cvFile"
                                  types={fileTypes}
                                />
                                <p className="mt-2 text-sm text-gray-500">
                                  {cvFile
                                    ? `File name: ${cvFile.name}`
                                    : "no files uploaded yet"}
                                </p>
                              </div>

                              {/* Empty DIV */}
                              <div></div>
                            </div>

                            <div className="mt-4">
                              <Checkbox name="termsAndConditions">
                                I have read and agree to the{" "}
                                <a
                                  className="dl-link"
                                  target="_blank"
                                  href="/privacypolicy/"
                                >
                                  Privacy Policy
                                </a>
                                .
                              </Checkbox>
                            </div>
                            <button
                              className="px-3
                                                                italic 
                                                                font-[700] 
                                                                w-fit
                                                                inline-flex 
                                                                items-center
                                                                p-1
                                                                group-hover:bg-dll-hover
                                                                rounded-[32px] 
                                                                bg-dll-link 
                                                                hover:text-dll-hover
                                                                text-white"
                              type="submit"
                            >
                              {loading ? "Submitting..." : "SUBMIT"}
                              <BiChevronRightCircle className="chev_icon" />
                            </button>
                          </Form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Layout>
        <DummyHero title={title} subtitle={type} />

        <div className="careerDetailWrapper">
          <div className="careerDetailWrapper__intro">
            <h2 className="careerDetailWrapper__intro__heading">Overview</h2>
          </div>

          <div className="careerDetailWrapper__content">
            <div className="careerDetailWrapper__content__row">
              <div className="careerDetailWrapper__content__row__left">
                <h2>Description</h2>
              </div>
              <div className="careerDetailWrapper__content__row__right">
                <ReactMarkdown>{open_to}</ReactMarkdown>
              </div>
            </div>

            {/* <div className='careerDetailWrapper__content__row'>
                            <div className='careerDetailWrapper__content__row__left'>
                                <h2>Requirements</h2>
                            </div>
                            <div className='careerDetailWrapper__content__row__right'>
                                <ReactMarkdown>
                                    {requirements}
                                </ReactMarkdown>
                            </div>
                        </div> */}

            <div className="careerDetailWrapper__content__row">
              <div className="careerDetailWrapper__content__row__left">
                <h2>Working Days</h2>
              </div>
              <div className="careerDetailWrapper__content__row__right">
                <ReactMarkdown>{working_day}</ReactMarkdown>
              </div>
            </div>

            <div className="careerDetailWrapper__content__row">
              <div className="careerDetailWrapper__content__row__left">
                <h2>Working Hours</h2>
              </div>
              <div className="careerDetailWrapper__content__row__right">
                <ReactMarkdown>{working_hour}</ReactMarkdown>
              </div>
            </div>

            <div className="careerDetailWrapper__content__row">
              <div className="careerDetailWrapper__content__row__left">
                <h2>Number of hiring positions</h2>
              </div>
              <div className="careerDetailWrapper__content__row__right">
                {number_of_hiring}
              </div>
            </div>

            <div className="careerDetailWrapper__content__row">
              <div className="careerDetailWrapper__content__row__left">
                <h2>Job Description</h2>
              </div>
              <div className="careerDetailWrapper__content__row__right">
                <ReactMarkdown>{job_description}</ReactMarkdown>
              </div>
            </div>

            <div className="careerDetailWrapper__content__row">
              <div className="careerDetailWrapper__content__row__left">
                <h2>Job Requirements</h2>
              </div>
              <div className="careerDetailWrapper__content__row__right">
                <ReactMarkdown>{job_requirement}</ReactMarkdown>
              </div>
            </div>

            <div className="careerDetailWrapper__content__row">
              <div className="careerDetailWrapper__content__row__left">
                <h2>What We Can Offer</h2>
              </div>
              <div className="careerDetailWrapper__content__row__right">
                <ReactMarkdown>{offer}</ReactMarkdown>
              </div>
            </div>
          </div>

          <div className="careerDetailWrapper__cta">
            <button onClick={openModal}>
              <span>Apply Here</span>
              <BiChevronRightCircle className="chev_icon" />
            </button>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const Head = ({ data }) => {
  // console.log("blogs: ", blogs);
  const title = data.strapiCareer.title
  const seoDesc = data.strapiCareer.seo.description
  const slug = data.strapiCareer.slug
  let meta = {
    title: `${title}`,
    description: `${seoDesc}`,
    url: `https://www.digitallaboratory.net/career/${slug}/`,
    image: logo,
  }

  return <Meta meta={meta} />
}

export const query = graphql`
  query ($id: String!) {
    strapiCareer(id: { eq: $id }) {
      id
      title
      slug
      type
      card_description
      portfolio
      is_portfolio_mandatory
      video_link
      sample_article
      image {
        alternativeText
        url
      }
      open_to {
        data {
          open_to
        }
      }

      working_day {
        data {
          working_day
        }
      }
      working_hour {
        data {
          working_hour
        }
      }
      seo {
        title
        description
      }
      number_of_hiring
      job_description {
        data {
          job_description
        }
      }
      job_requirement {
        data {
          job_requirement
        }
      }
      offer {
        data {
          offer
        }
      }
    }
  }
`

export default Career
